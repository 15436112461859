@if (!initializing) {
  <div style="display:flex; height: 100vh; overflow: hidden;">

    @if (!isMobile) {

      <div class="navigation-container no-print">
        @if (preferences.dock == 'start' || preferences.dock == undefined) {
          <atw-dock class="dock-start"/>
        }
        @if (!isApp) {
          <app-navigation class="no-print"/>
        }
      </div>

      @if (preferences.dock == 'end' || preferences.dock == 'bottom') {
        <atw-dock class="no-print dock-{{preferences.dock}}"/>
      }
    }@else{
      <div class="navigation-container no-print">
        <app-navigation [isMobile]="true" class="no-print"/>
      </div>
      <atw-dock [isMobile]="true" class="no-print dock-bottom"/>
    }
    <div style="height: 100vh; flex-grow: 1; overflow: hidden auto">
      <router-outlet></router-outlet>
    </div>
  </div>
}

<atw-upload-toast class="no-print"
                  style="display:block;position: fixed;bottom: 10px;right:10px;"/>

@if (initializing) {
  <app-loading/>
}
